import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Box, Flex, Heading, Text } from 'rebass';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import styled from '@emotion/styled';
import { SEO } from '@components';

const Wrapper = styled.div``;

const Content = styled.div`
  margin-bottom: 5rem;
  max-width: 70rem;
  width: 100%;

  a {
    &,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary};
    }
    text-decoration: none;
  }

  code.language-text {
    background: rgba(165, 172, 192, 0.16);
    border-radius: 2px;
    border: 1px solid rgba(165, 172, 192, 0.2);
    box-sizing: border-box;
  }
`;

const TOCList = styled(Box)`
  a {
    text-decoration: none;
    word-break: break-word;
    &:hover {
      font-weight: bold;
    }
  }
`;

export default function Template({ data }) {
  const { mdx } = data;
  const { frontmatter, body, tableOfContents } = mdx;

  return (
    <Wrapper>
      <Flex justifyContent="space-between">
        <SEO title={frontmatter.title} />
        <Box>
          <Heading fontSize={['2xl', null, '3xl']} mb="2rem">
            {frontmatter.title}
          </Heading>
          <Content>
            <MDXRenderer>{body}</MDXRenderer>
          </Content>
        </Box>

        {typeof tableOfContents.items === 'undefined' ? null : (
          <Box ml="2rem" sx={{ position: 'relative' }} display={['none', null, null, null, 'block']}>
            <Box sx={{ position: 'sticky', top: '10rem' }}>
              <Text as="h3" variant="body" fontSize="xs" textAlign="right" mb="1.5rem" fontWeight="semibold">
                CONTENTS
              </Text>

              <TOCList as="ul">
                {tableOfContents.items.map((i) => (
                  <Text as="li" variant="body" key={i.url} textAlign="right" py="0.6rem">
                    <Text as={AnchorLink} href={i.url} offset={100}>
                      {i.title}
                    </Text>
                  </Text>
                ))}
              </TOCList>
            </Box>
          </Box>
        )}
      </Flex>
    </Wrapper>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      tableOfContents
      frontmatter {
        path
        title
      }
    }
  }
`;
